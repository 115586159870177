<template>
  <div class="home">
    <img alt="[logo_clinica]" src="https://starling-clinicas-productos.s3.us-east-2.amazonaws.com/clinica.jpeg">
    <HelloWorld msg="Bienvenido"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
